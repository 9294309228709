import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { CsatSurveyModalComponent } from 'src/app/csat-survey/modals/csat-survey-modal/csat-survey-modal.component';
import { GraphqlService } from 'src/app/graphql/graphql.service';
import { SecurityService } from 'src/app/security/security.service';

// Copied and modified from 'ngx-toastr': https://github.com/scttcper/ngx-toastr/commits/master/src/lib/toastr/toast.component.ts

@Component({
	selector: 'pk-broker-csat-toast',
	templateUrl: './csat-toast.component.html',
	styleUrls: ['./csat-toast.component.scss'],
	animations: [
		trigger('flyInOut', [
			state('inactive', style({ opacity: 0 })),
			state('active', style({ opacity: 1 })),
			state('removed', style({ opacity: 0 })),
			transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
			transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
		]),
	],
	preserveWhitespaces: false,
})
export class CsatToastComponent extends Toast {
	constructor(
		protected toastrService: ToastrService,
		public toastPackage: ToastPackage,
		public graphqlService: GraphqlService,
		public modalService: BsModalService,
		public securityService: SecurityService,
	) {
		super(toastrService, toastPackage);
	}

	async showCsatFormModal() {
		const csatSurvey = (this.options as any).csatSurvey;
		this.graphqlService.updateUserSurvey(csatSurvey.id, { clickedInitialLink: true });
		this.modalService.show(CsatSurveyModalComponent, {
			class: 'modal-dialog-centered nps-csat-modal',
			initialState: {
				csatSurvey,
				contractId: (this.options as any).contractId,
				userSurveyCategoryId: (this.options as any).userSurveyCategoryId,
			},
		});
	}
}
